import DataPair from 'components/DataPair'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Airport, AirportFields, FlightRouteType, ViasData, ViasFields } from 'types'

export interface AirportDataPairProps {
	routeType: FlightRouteType
	airport: Airport
	via: ViasData | null
	showVia: boolean
}

export default function AirportDataPair(props: AirportDataPairProps): JSX.Element {
	const { routeType, airport, via, showVia } = props
	const { t } = useTranslation()

	const { [AirportFields.fidsExhibitionName]: airportExhibitionName } = airport
	const airportFieldLabel = (
		<>
			<span>{routeType === 'arrival' ? t('origin') : t('destination')}</span>
			{via && (
				<>
					<span> /</span>
					<span className="dark-blue italicized">{t('via')}</span>
				</>
			)}
		</>
	)

	const viaAirport = via?.[ViasFields.airport]
	const viaAirportExhibitionName = viaAirport?.[AirportFields.fidsExhibitionName]
	const airportFieldValue = useMemo(() => {
		if (!viaAirportExhibitionName) return <span>{airportExhibitionName}</span>

		return showVia ? (
			<span className="italicized dark-blue">{viaAirportExhibitionName}</span>
		) : (
			<span>{airportExhibitionName}</span>
		)
	}, [viaAirportExhibitionName, showVia])

	return <DataPair label={airportFieldLabel} value={airportFieldValue} />
}
