import { format } from 'date-fns'
import { DateAndTimeType, FormattedTimesType } from 'types/datetime'

export const adjustForUTCOffset = (date: Date): Date =>
	new Date(
		date.getUTCFullYear(),
		date.getUTCMonth(),
		date.getUTCDate(),
		date.getUTCHours(),
		date.getUTCMinutes(),
		date.getUTCSeconds(),
	)

export const formatDateAndTime = (scheduleDateTimeObj: Date): DateAndTimeType => ({
	date: format(scheduleDateTimeObj, 'dd/MM/yy'),
	time: format(scheduleDateTimeObj, 'HH:mm'),
})

export const formatDateTimeUTC = (dateTimeObj: Date): DateAndTimeType => {
	const dateUtc = adjustForUTCOffset(dateTimeObj)
	return formatDateAndTime(dateUtc)
}

export const formatTime = (): FormattedTimesType => {
	const now = new Date()
	return {
		localTime: formatDateAndTime(now).time,
		utcTime: formatDateTimeUTC(now).time,
	}
}
