import { extendTheme } from '@chakra-ui/react'
import { brand } from './color'

const theme = extendTheme({
	colors: {
		brand: {
			...brand,
		},
	},
})

export default theme
