import { FlightStatusTypeEnum } from './status'

export enum FidsSettingsFields {
	reloadScreenTime = 'reload_screen_time',
	destinationOriginExibitionTime = 'destination_origin_exibition_time',
	viaExibitionTime = 'via_exibition_time',
	fidsFooterSpeed = 'fids_footer_speed',
	fidsFooterMessagePrimaryLanguage = 'fids_footer_message_primary_language',
	fidsFooterMessageSecondaryLanguage = 'fids_footer_message_secondary_language',
	hiddenStatusCodes = 'hidden_status_codes',
}

export interface FidsSettings {
	[FidsSettingsFields.reloadScreenTime]: number
	[FidsSettingsFields.destinationOriginExibitionTime]: number
	[FidsSettingsFields.viaExibitionTime]: number
	[FidsSettingsFields.fidsFooterSpeed]: number
	[FidsSettingsFields.fidsFooterMessagePrimaryLanguage]: string | null
	[FidsSettingsFields.fidsFooterMessageSecondaryLanguage]: string | null
	[FidsSettingsFields.hiddenStatusCodes]: FlightStatusTypeEnum[]
}
