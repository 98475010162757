import * as React from 'react'

import { useInterval } from '@chakra-ui/react'

const { useState } = React

interface ToggleTimeProps {
	primaryTime: number
	secondaryTime: number
}

const useToggleTime = (props: ToggleTimeProps): boolean => {
	const { primaryTime, secondaryTime } = props
	const [displayPrimary, setDisplayPrimary] = useState<boolean>(true)

	const [reloadTime, nextInterval] = displayPrimary
		? [primaryTime, secondaryTime]
		: [secondaryTime, primaryTime]

	useInterval(() => {
		if (!nextInterval) return
		setDisplayPrimary(!displayPrimary)
	}, reloadTime * 1000)

	return displayPrimary
}

export default useToggleTime
