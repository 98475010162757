import 'App.scss'
import BackupPage from 'components/BackupPage'
import FidsList from 'components/FidsList'

import { RouterProvider } from 'react-router'
import { createBrowserRouter, Navigate } from 'react-router-dom'

const rootRouter = createBrowserRouter([
	{
		path: '/',
		element: <BackupPage />,
	},
	{
		path: '/:airportCode/:flightRoute',
		element: <FidsList />,
	},
	{
		path: '*',
		element: <Navigate to="/" replace />,
	},
])

export function App() {
	return <RouterProvider router={rootRouter} />
}
