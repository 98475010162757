import { Spinner } from '@chakra-ui/react'

import './styles.scss'

export function LoadingComponent(props: any) {
	return <Spinner label="loading" {...props} />
}

export function LoadingSmall() {
	return (
		<div className="loadingWrapper">
			<LoadingComponent label="loading" height="1rem" width="1rem" />
		</div>
	)
}

LoadingSmall.defaultProps = {
	classname: '',
}
