import { Icon, IconProps } from '@chakra-ui/react'

function ClockCircleIcon(props: IconProps) {
	return (
		<Icon viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M10.2 0C8.22214 0 6.28874 0.58649 4.64425 1.6853C2.99976 2.78412 1.71804 4.3459 0.96116 6.17317C0.204284 8.00043 0.00625066 10.0111 0.392103 11.9509C0.777955 13.8907 1.73036 15.6725 3.12889 17.0711C4.52741 18.4696 6.30924 19.422 8.24905 19.8079C10.1889 20.1937 12.1995 19.9957 14.0268 19.2388C15.8541 18.4819 17.4158 17.2002 18.5147 15.5557C19.6135 13.9112 20.2 11.9778 20.2 10C20.2 8.68678 19.9413 7.38642 19.4388 6.17317C18.9362 4.95991 18.1996 3.85752 17.271 2.92893C16.3424 2.00035 15.24 1.26375 14.0268 0.761205C12.8135 0.258658 11.5132 0 10.2 0ZM10.2 18C8.61771 18 7.07099 17.5308 5.75539 16.6518C4.4398 15.7727 3.41442 14.5233 2.80892 13.0615C2.20342 11.5997 2.04499 9.99113 2.35367 8.43928C2.66236 6.88743 3.42428 5.46197 4.5431 4.34315C5.66192 3.22433 7.08739 2.4624 8.63923 2.15372C10.1911 1.84504 11.7996 2.00346 13.2614 2.60896C14.7232 3.21447 15.9727 4.23984 16.8517 5.55544C17.7308 6.87103 18.2 8.41775 18.2 10C18.2 12.1217 17.3571 14.1566 15.8568 15.6569C14.3565 17.1571 12.3217 18 10.2 18ZM10.2 4C9.93474 4 9.68039 4.10536 9.49285 4.29289C9.30531 4.48043 9.19996 4.73478 9.19996 5V9.42L7.09996 10.63C6.90731 10.7392 6.75635 10.9092 6.67074 11.1134C6.58512 11.3176 6.56971 11.5444 6.6269 11.7583C6.6841 11.9722 6.81067 12.1611 6.98677 12.2953C7.16287 12.4296 7.37854 12.5015 7.59996 12.5C7.77513 12.5012 7.94755 12.4564 8.09996 12.37L10.7 10.87L10.79 10.78L10.95 10.65C10.9891 10.6005 11.0226 10.5468 11.05 10.49C11.0825 10.4363 11.1094 10.3793 11.13 10.32C11.1572 10.2564 11.174 10.1889 11.18 10.12L11.2 10V5C11.2 4.73478 11.0946 4.48043 10.9071 4.29289C10.7195 4.10536 10.4652 4 10.2 4Z"
				fill="currentColor"
			/>
		</Icon>
	)
}

export default ClockCircleIcon
