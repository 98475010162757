import useManagedTitle from 'hooks/useManagedTitle'
import './styles.scss'

const CCR_WHITE_LOGO = '/img/ccr_logo_white.png'
const CCR_LOGO_ALT = 'CCR Aeroportos'

export default function BackupPage(): JSX.Element {
	useManagedTitle(null, null)
	return (
		<div className="backdrop dark-blue">
			<img src={CCR_WHITE_LOGO} alt={CCR_LOGO_ALT} />
		</div>
	)
}
