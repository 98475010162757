import * as flags from 'assets/icons/language-flags'
import { useTranslation } from 'react-i18next'
import './styles.scss'

const languageOptions = [
	{ value: 'en', flag: flags.en },
	{ value: 'pt-BR', flag: flags.ptBR },
]

export default function LanguageSwitcher() {
	const { t, i18n } = useTranslation()

	return (
		<div className="language-switcher">
			<span>{t('selectLanguage')}</span>

			{languageOptions.map((languageOption) => (
				<button
					style={{
						border:
							i18n.language.toLowerCase() === languageOption.value.toLowerCase()
								? '2px solid black'
								: 'none',
					}}
					key={languageOption.value}
					className="btn-language"
					onClick={() => {
						i18n.changeLanguage(languageOption.value)
					}}
					type="button"
				>
					<img className="flag" src={languageOption.flag} alt={languageOption.value} />
				</button>
			))}
		</div>
	)
}
