import { Icon, IconProps } from '@chakra-ui/react'

function DepartureAirplaneIcon(props: IconProps) {
	return (
		<Icon
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#clip0_354_7613)">
				<path
					d="M5.00018 37.9999H43.0002V41.9999H5.00018V37.9999ZM44.1402 19.2799C43.7202 17.6799 42.0602 16.7199 40.4602 17.1599L29.8402 19.9999L16.0402 7.13989L12.1802 8.15989L20.4602 22.4999L10.5202 25.1599L6.58018 22.0799L3.68018 22.8599L7.32018 29.1799L8.86018 31.8399L12.0602 30.9799L22.6802 28.1399L31.3802 25.8199L42.0002 22.9799C43.6202 22.5199 44.5602 20.8799 44.1402 19.2799Z"
					fill="currentColor"
				/>
			</g>
			<defs>
				<clipPath id="clip0_354_7613">
					<rect width="48" height="48" fill="currentColor" />
				</clipPath>
			</defs>
		</Icon>
	)
}

export default DepartureAirplaneIcon
