/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from 'axios'

const api = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}`,
	withCredentials: false,
	xsrfCookieName: 'csrftoken',
	xsrfHeaderName: 'X-CSRFToken',
})

api.interceptors.request.use(
	(config: any) => {
		const newConfig = {
			...config,
			headers: {
				'Content-Type': 'application/json',
				...config.headers,
			},
		}
		return newConfig
	},
	(error: any) => Promise.reject(error),
)

const get = async (url: string, config?: any): Promise<any> => {
	const response: AxiosResponse = await api.get(url, config)
	return response.data
}

const patch = async (url: string, data: any): Promise<any> => {
	const response: AxiosResponse = await api.patch(url, data)
	return response.data
}

const post = async (url: string, data: any, config?: any): Promise<any> => {
	const response: AxiosResponse = await api.post(url, data, config || {})

	return response.data
}

const remove = async (url: string, config: any): Promise<any> => {
	const response: AxiosResponse = await api.delete(url, config || {})
	return response.data
}

export { get, patch, post, remove }
