import FidsCard from 'components/FidsCard'
import Footer from 'components/Footer'
import { LoadingSmall } from 'components/Loading'
import useAutomaticRefresh from 'hooks/useAutomaticRefresh'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FidsService, { FidsDataResponse } from 'services/fids'
import { ArrivalFlight, DepartureFlight, FidsSettings, FlightFields, FlightRouteType } from 'types'
import { FidsSettingsFields } from 'types/settings'
import useStandardToast from 'utils/toast'
import './styles.scss'

import DateSeparator from 'components/DateSeparator'
import Header from 'components/Header'
import useManagedTitle from 'hooks/useManagedTitle'
import { useParams } from 'react-router-dom'
import useToggleTime from 'utils/toggle'
import { insertDateSeparators } from './helpers'

const VIA_EXHIBITION_DEFAULT_SECONDS = 5

export default function FidsList() {
	const { airportCode, flightRoute } = useParams()
	useManagedTitle(airportCode, flightRoute as FlightRouteType | null | undefined)
	const { t } = useTranslation()
	const [data, setData] = useState<(ArrivalFlight | DepartureFlight)[] | null>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [settings, setSettings] = useState<FidsSettings | null>(null)
	const { showErrorToast } = useStandardToast()

	const primaryLanguageMessage =
		settings?.[FidsSettingsFields.fidsFooterMessagePrimaryLanguage] || ''
	const secondaryLanguageMessage =
		settings?.[FidsSettingsFields.fidsFooterMessageSecondaryLanguage] || ''
	const hiddenStatusCodes = settings?.[FidsSettingsFields.hiddenStatusCodes] || []

	const formattedData = useMemo(
		() =>
			insertDateSeparators(
				data || [],
				flightRoute === 'arrival'
					? FlightFields.scheduledArrivalDatetime
					: FlightFields.scheduledDepartureDatetime,
			),
		[data],
	)

	const loadDataAndSettings = useCallback(() => {
		if (isLoading) return

		setIsLoading(true)
		if (airportCode && flightRoute) {
			FidsService.loadFidsData(airportCode, flightRoute)
				.then((response: FidsDataResponse) => {
					setSettings(response.settings)
					setData(response.data)
				})
				.catch(() => {
					showErrorToast(t('errorFetchingData'))
				})
				.finally(() => setIsLoading(false))
		}
	}, [airportCode, flightRoute, isLoading])

	useEffect(() => {
		loadDataAndSettings()
	}, [])

	useAutomaticRefresh({
		refreshTime: settings?.[FidsSettingsFields.reloadScreenTime] ?? 30,
		refreshData: loadDataAndSettings,
	})

	const viaExhibitionTime =
		settings?.[FidsSettingsFields.viaExibitionTime] || VIA_EXHIBITION_DEFAULT_SECONDS

	const showVia = !useToggleTime({
		primaryTime: viaExhibitionTime,
		secondaryTime: viaExhibitionTime,
	})

	return (
		<main>
			<Header />
			<section>
				{data &&
					flightRoute &&
					formattedData.map((item) =>
						item.content_type === 'flight' ? (
							<FidsCard
								key={item[FlightFields.flightNumber]}
								routeType={flightRoute as FlightRouteType}
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								data={item as any}
								showVia={showVia}
								hiddenStatusCodes={hiddenStatusCodes}
							/>
						) : (
							<DateSeparator date={item.date} />
						),
					)}
				{isLoading && <LoadingSmall />}
			</section>
			<Footer
				primaryLanguageMessage={primaryLanguageMessage}
				secondaryLanguageMessage={secondaryLanguageMessage}
			/>
		</main>
	)
}
