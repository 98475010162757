import { Icon, IconProps } from '@chakra-ui/react'

function ArrowUpCircleIcon(props: IconProps) {
	return (
		<Icon viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M10.71 6.29C10.6149 6.19896 10.5028 6.12759 10.38 6.08C10.1365 5.97998 9.86347 5.97998 9.62 6.08C9.49725 6.12759 9.38511 6.19896 9.29 6.29L6.29 9.29C6.1017 9.4783 5.99591 9.7337 5.99591 10C5.99591 10.2663 6.1017 10.5217 6.29 10.71C6.47831 10.8983 6.7337 11.0041 7 11.0041C7.26631 11.0041 7.5217 10.8983 7.71 10.71L9 9.41V13C9 13.2652 9.10536 13.5196 9.2929 13.7071C9.48043 13.8946 9.73479 14 10 14C10.2652 14 10.5196 13.8946 10.7071 13.7071C10.8946 13.5196 11 13.2652 11 13V9.41L12.29 10.71C12.383 10.8037 12.4936 10.8781 12.6154 10.9289C12.7373 10.9797 12.868 11.0058 13 11.0058C13.132 11.0058 13.2627 10.9797 13.3846 10.9289C13.5064 10.8781 13.617 10.8037 13.71 10.71C13.8037 10.617 13.8781 10.5064 13.9289 10.3846C13.9797 10.2627 14.0058 10.132 14.0058 10C14.0058 9.86799 13.9797 9.73728 13.9289 9.61542C13.8781 9.49356 13.8037 9.38296 13.71 9.29L10.71 6.29ZM10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433281 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21446 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C18 12.1217 17.1572 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18Z"
				fill="currentColor"
			/>
		</Icon>
	)
}

export default ArrowUpCircleIcon
