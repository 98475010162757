export enum AirlineFields {
	icaoCode = 'icao_code',
	logoUrl = 'logo_url',
	smallLogoFids = 'small_logo_fids',
	largeLogoFids = 'large_logo_fids',
}

export interface Airline {
	[AirlineFields.icaoCode]: string
	[AirlineFields.logoUrl]: string | null
	[AirlineFields.smallLogoFids]: string | null
	[AirlineFields.largeLogoFids]: string | null
}
