import { Icon, IconProps } from '@chakra-ui/react'

function LogoCCRIcon(props: IconProps) {
	return (
		<Icon viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M36.4075 22.4095C35.0874 25.913 32.9789 29.1172 30.2893 31.7202C27.2291 31.5105 24.4967 30.1075 22.5542 27.9714C24.8947 23.8365 28.334 20.3255 32.4226 17.9074C32.8033 17.6823 32.5928 17.0996 32.1561 17.1708C27.5638 17.9201 23.2341 20.2477 20.0837 23.6757C19.739 22.5801 19.5527 21.4143 19.5527 20.2051C19.5527 19.2344 19.673 18.292 19.8985 17.3915C22.9437 14.4744 27.044 12.6752 31.2496 12.3956C31.5404 12.3765 31.5601 11.9565 31.2723 11.9114C28.0372 11.4041 24.6375 11.9457 21.7302 13.4554C23.8252 10.5518 27.2361 8.66169 31.0886 8.66169C37.4707 8.66169 42.7213 14.0003 42.6236 20.3855C42.5466 25.4142 39.2555 29.6622 34.7156 31.1656C36.1035 28.4997 36.8561 25.5016 36.8797 22.4956C36.8818 22.2231 36.5037 22.1544 36.4075 22.4095Z"
				fill="#0087FF"
			/>
			<path
				d="M15.5647 52.1335L15.0738 50.6761H15.0637L14.5778 52.1335H15.5647ZM15.7367 52.6495H14.4059L14.178 53.3279H13.3281L14.6133 49.998H15.6456L16.9308 53.3279H15.9643L15.7367 52.6495Z"
				fill="white"
			/>
			<path
				d="M17.4478 49.998H19.8059V50.6508H18.2777V51.3998H19.4619V51.9059H18.2777V52.6751H19.8514V53.3279H17.4478V49.998Z"
				fill="white"
			/>
			<path
				d="M21.9869 51.5871C22.336 51.5871 22.5131 51.4302 22.5131 51.1214C22.5131 50.8279 22.3309 50.6508 21.9463 50.6508H21.2277V51.5871H21.9869ZM20.3978 49.998H21.9514C22.8318 49.998 23.3076 50.3624 23.3076 50.9899C23.3076 51.4148 23.1253 51.6831 22.8117 51.7742V51.7844C23.1407 51.8602 23.2874 52.1233 23.2874 52.4979V53.3279H22.4574V52.5232C22.4574 52.2195 22.346 52.0881 22.0324 52.0881H21.2277V53.3279H20.3978V49.998Z"
				fill="white"
			/>
			<path
				d="M26.5758 51.6527C26.5758 50.9899 26.176 50.6003 25.6498 50.6003C25.1235 50.6003 24.7237 50.9899 24.7237 51.6527C24.7237 52.3257 25.1235 52.7256 25.6498 52.7256C26.176 52.7256 26.5758 52.3257 26.5758 51.6527ZM23.8634 51.6478C23.8634 50.58 24.6428 49.9475 25.6498 49.9475C26.6569 49.9475 27.436 50.58 27.436 51.6478C27.436 52.7358 26.6569 53.3784 25.6498 53.3784C24.6428 53.3784 23.8634 52.7358 23.8634 51.6478Z"
				fill="white"
			/>
			<path
				d="M29.4907 51.8198C29.8399 51.8198 30.0322 51.6325 30.0322 51.2428C30.0322 50.8634 29.8196 50.6508 29.4249 50.6508H28.8481V51.8198H29.4907ZM28.0182 49.998H29.4503C30.3004 49.998 30.8114 50.3979 30.8114 51.2025C30.8114 51.9414 30.3964 52.3765 29.5261 52.3765H28.8481V53.3279H28.0182V49.998Z"
				fill="white"
			/>
			<path
				d="M33.9943 51.6527C33.9943 50.9899 33.5946 50.6003 33.0683 50.6003C32.542 50.6003 32.1422 50.9899 32.1422 51.6527C32.1422 52.3257 32.542 52.7256 33.0683 52.7256C33.5946 52.7256 33.9943 52.3257 33.9943 51.6527ZM31.2819 51.6478C31.2819 50.58 32.0613 49.9475 33.0683 49.9475C34.0754 49.9475 34.8546 50.58 34.8546 51.6478C34.8546 52.7358 34.0754 53.3784 33.0683 53.3784C32.0613 53.3784 31.2819 52.7358 31.2819 51.6478Z"
				fill="white"
			/>
			<path
				d="M37.0256 51.5871C37.3749 51.5871 37.5519 51.4302 37.5519 51.1214C37.5519 50.8279 37.37 50.6508 36.9851 50.6508H36.2667V51.5871H37.0256ZM35.4368 49.998H36.9904C37.8707 49.998 38.3464 50.3624 38.3464 50.9899C38.3464 51.4148 38.1644 51.6831 37.8505 51.7742V51.7844C38.1795 51.8602 38.3263 52.1233 38.3263 52.4979V53.3279H37.4963V52.5232C37.4963 52.2195 37.385 52.0881 37.0713 52.0881H36.2667V53.3279H35.4368V49.998Z"
				fill="white"
			/>
			<path
				d="M39.7082 50.6508H38.7619V49.998H41.4793V50.6508H40.5381V53.3279H39.7082V50.6508Z"
				fill="white"
			/>
			<path
				d="M44.4811 51.6527C44.4811 50.9899 44.0815 50.6003 43.5551 50.6003C43.0288 50.6003 42.6291 50.9899 42.6291 51.6527C42.6291 52.3257 43.0288 52.7256 43.5551 52.7256C44.0815 52.7256 44.4811 52.3257 44.4811 51.6527ZM41.7689 51.6478C41.7689 50.58 42.5481 49.9475 43.5551 49.9475C44.5621 49.9475 45.3415 50.58 45.3415 51.6478C45.3415 52.7358 44.5621 53.3784 43.5551 53.3784C42.5481 53.3784 41.7689 52.7358 41.7689 51.6478Z"
				fill="white"
			/>
			<path
				d="M45.8657 52.1841H46.6046C46.6046 52.5434 46.7514 52.7762 47.3081 52.7762C47.8696 52.7762 47.9963 52.5737 47.9963 52.3612C47.9963 52.2195 47.9355 52.1284 47.8039 52.083C47.6571 52.0322 47.3129 51.9817 47.0347 51.9412C46.7057 51.8906 46.3719 51.8247 46.1795 51.6984C45.9669 51.5616 45.8557 51.3288 45.8557 51.0101C45.8557 50.3826 46.3464 49.9475 47.3081 49.9475C48.3657 49.9475 48.7096 50.3877 48.7096 51.1367H47.9759C47.9759 50.7573 47.7786 50.5497 47.3231 50.5497C46.8729 50.5497 46.6804 50.7166 46.6804 50.9392C46.6804 51.1013 46.726 51.1923 46.8423 51.2428C46.9689 51.2985 47.3181 51.3542 47.6013 51.3998C47.9759 51.4606 48.2594 51.5213 48.4567 51.6275C48.6946 51.754 48.8211 51.9919 48.8211 52.3056C48.8211 52.8724 48.5021 53.3784 47.3129 53.3784C46.1693 53.3784 45.8657 52.7966 45.8657 52.1841Z"
				fill="white"
			/>
			<path
				d="M23.092 43.5016C22.2121 44.038 21.1392 44.3814 20.0662 44.3814C17.9632 44.3814 16.5038 43.0509 16.5038 41.1409C16.5038 39.51 17.5768 38.3727 19.0792 38.3727C20.3668 38.3727 21.1391 39.1665 21.1391 40.4543H24.2509C24.2509 37.2782 22.3194 35.3039 19.2077 35.3039C15.6885 35.3039 13.2206 37.7503 13.2206 41.2696C13.2206 44.9178 15.8815 47.4501 19.7013 47.4501C21.1175 47.4501 22.534 47.1069 23.7573 46.4201L23.092 43.5016ZM35.4849 43.5016C34.5836 44.038 33.532 44.3814 32.4589 44.3814C30.3561 44.3814 28.8967 43.0509 28.8967 41.1409C28.8967 39.51 29.9696 38.3727 31.4718 38.3727C32.7379 38.3727 33.5319 39.1665 33.5319 40.4543H36.6435C36.6435 37.2782 34.7123 35.3039 31.6007 35.3039C28.0812 35.3039 25.6134 37.7503 25.6134 41.2696C25.6134 44.9178 28.2745 47.4501 32.0943 47.4501C33.5105 47.4501 34.9267 47.1069 36.1502 46.4201L35.4849 43.5016ZM46.3114 43.5229C47.9421 43.0938 48.9294 41.699 48.9294 39.789C48.9294 37.2139 47.1267 35.6473 44.1223 35.6473H38.6716V47.1069H41.6545V44.5961H43.6717C44.9593 44.5961 45.7319 45.5617 45.7319 47.1069H48.822C48.822 45.0896 47.9851 43.6088 46.3114 43.1796V43.5229ZM41.6545 38.5014H44.1223C45.1954 38.5014 45.8605 39.1239 45.8605 40.1324C45.8605 41.1409 45.1954 41.7419 44.1223 41.7419H41.6545V38.5014Z"
				fill="white"
			/>
		</Icon>
	)
}

export default LogoCCRIcon
