import { useInterval } from '@chakra-ui/react'

export interface PaginationArgs {
	refreshTime: number
	refreshData: () => void
}

const useAutomaticRefresh = (args: PaginationArgs): void => {
	const { refreshTime, refreshData } = args
	useInterval(refreshData, refreshTime * 1000)
}

export default useAutomaticRefresh
