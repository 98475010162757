import { AlertStatus, ToastPosition, useToast } from '@chakra-ui/react'
import { useCallback } from 'react'

interface StandardToastReturn {
	showSuccessToast: (message: string) => void
	showErrorToast: (message: string) => void
	showCustomToast: (
		message: string,
		status: AlertStatus,
		position?: ToastPosition | undefined,
		isClosable?: boolean,
	) => void
}

const useStandardToast = (): StandardToastReturn => {
	const toast = useToast()

	const showErrorToast = useCallback(
		(message: string) =>
			toast({
				title: message,
				status: 'error',
				position: 'top-right',
				isClosable: true,
			}),
		[toast],
	)

	const showSuccessToast = useCallback(
		(message: string) =>
			toast({
				title: message,
				status: 'success',
				position: 'top-right',
				isClosable: true,
			}),
		[toast],
	)

	const showCustomToast = useCallback(
		(
			message: string,
			status: AlertStatus,
			position?: ToastPosition | undefined,
			isClosable?: boolean,
		) =>
			toast({
				title: message,
				status,
				position: position ?? 'top-right',
				isClosable: isClosable ?? true,
			}),
		[toast],
	)

	return {
		showSuccessToast,
		showErrorToast,
		showCustomToast,
	}
}

export default useStandardToast
