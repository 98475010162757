import { Icon, IconProps } from '@chakra-ui/react'

function ArrivalAirplaneIcon(props: IconProps) {
	return (
		<Icon
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M5 38H43V42H5V38ZM19.36 26.54L28.06 28.86L38.68 31.7C40.28 32.12 41.92 31.18 42.36 29.58C42.78 27.98 41.84 26.34 40.24 25.9L29.62 23.06L24.1 5.02L20.24 4V20.56L10.3 17.9L8.44 13.26L5.54 12.48V22.82L8.74 23.68L19.36 26.54Z"
				fill="currentColor"
			/>
		</Icon>
	)
}

export default ArrivalAirplaneIcon
