import { useInterval } from '@chakra-ui/react'
import { useState } from 'react'
import { FormattedTimesType } from 'types/datetime'
import { formatTime } from 'utils/datetime'

type UseUpdateCurrentTimeReturnType = {
	currentTime: FormattedTimesType
}

type UseUpdateCurrentTimeType = {
	refreshInterval?: number
}

export function useUpdateCurrentTime(
	props?: UseUpdateCurrentTimeType,
): UseUpdateCurrentTimeReturnType {
	const [currentTime, setCurrentTime] = useState<FormattedTimesType>(formatTime())

	const DEFAULT_REFRESH_INTERVAL = 10 * 1000
	const refreshInterval = props?.refreshInterval || DEFAULT_REFRESH_INTERVAL

	const updateFormattedTime = () => {
		setCurrentTime(formatTime())
	}

	useInterval(() => {
		updateFormattedTime()
	}, refreshInterval)

	return { currentTime }
}
