import { Icon, IconProps } from '@chakra-ui/react'

function TakingOffIcon(props: IconProps) {
	return (
		<Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M23.0001 9.31998C23.0628 9.06305 23.0271 8.79197 22.9001 8.55998C22.576 7.99763 22.1441 7.5048 21.6291 7.10978C21.114 6.71476 20.5261 6.42533 19.8989 6.25809C19.2718 6.09085 18.6178 6.0491 17.9745 6.13523C17.3312 6.22135 16.7112 6.43367 16.1501 6.75998L14.0001 7.99998L9.00011 5.64998C8.85796 5.57634 8.7002 5.5379 8.54011 5.5379C8.38002 5.5379 8.22226 5.57634 8.08011 5.64998L5.08011 7.37998C4.93185 7.4655 4.80802 7.58765 4.72047 7.73472C4.63293 7.8818 4.5846 8.04888 4.58011 8.21998C4.57527 8.39235 4.61508 8.56305 4.69568 8.71549C4.77628 8.86793 4.89493 8.99694 5.04011 9.08998L8.34011 11.17L6.60011 12.17L1.82011 12.75C1.62796 12.7738 1.44683 12.8528 1.29873 12.9775C1.15063 13.1022 1.04192 13.2673 0.985801 13.4526C0.929679 13.6379 0.92856 13.8355 0.982579 14.0214C1.0366 14.2073 1.14343 14.3736 1.29011 14.5L4.83011 17.56C5.29616 18.0048 5.89301 18.2877 6.53236 18.367C7.1717 18.4462 7.81957 18.3175 8.38011 18L22.5001 9.92998C22.6205 9.86752 22.7268 9.78123 22.8128 9.67636C22.8987 9.5715 22.9625 9.45025 23.0001 9.31998ZM7.47011 16.32C7.27802 16.4257 7.05651 16.4653 6.83969 16.4328C6.62286 16.4003 6.42274 16.2974 6.27011 16.14L4.37011 14.51L7.10011 14.18C7.23426 14.1628 7.36354 14.1186 7.48011 14.05L10.8401 12.12C10.9898 12.0336 11.1145 11.9099 11.2022 11.7609C11.2898 11.6119 11.3373 11.4428 11.3401 11.27C11.3422 11.0984 11.3 10.9291 11.2177 10.7785C11.1354 10.6278 11.0157 10.5009 10.8701 10.41L7.57011 8.31998L8.67011 7.68998L13.6701 10.01C13.8123 10.0836 13.97 10.1221 14.1301 10.1221C14.2902 10.1221 14.448 10.0836 14.5901 10.01L17.1501 8.52998C17.6747 8.23599 18.2756 8.10644 18.8747 8.15815C19.4739 8.20986 20.0436 8.44045 20.5101 8.81998L7.47011 16.32Z"
				fill="currentColor"
			/>
		</Icon>
	)
}

export default TakingOffIcon
