import { Icon, IconProps } from '@chakra-ui/react'

function CheckmarkCircle(props: IconProps) {
	return (
		<Icon viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M12.72 6.79L8.43001 11.09L6.78001 9.43999C6.69035 9.33532 6.58004 9.2503 6.45597 9.19027C6.3319 9.13025 6.19677 9.09651 6.05906 9.0912C5.92133 9.08588 5.78401 9.10908 5.65568 9.15936C5.52735 9.20963 5.41081 9.28589 5.31335 9.38334C5.2159 9.4808 5.13964 9.59735 5.08936 9.72567C5.03909 9.854 5.01589 9.99133 5.0212 10.129C5.02653 10.2668 5.06025 10.4019 5.12027 10.526C5.1803 10.65 5.26532 10.7603 5.37001 10.85L7.72 13.21C7.81345 13.3026 7.92425 13.376 8.0461 13.4258C8.16794 13.4756 8.2984 13.5008 8.43001 13.5C8.69234 13.4988 8.94374 13.3947 9.13 13.21L14.13 8.20999C14.2238 8.11703 14.2982 8.00644 14.3489 7.88458C14.3997 7.76272 14.4258 7.63201 14.4258 7.5C14.4258 7.36799 14.3997 7.23728 14.3489 7.11542C14.2982 6.99356 14.2238 6.88296 14.13 6.79C13.9426 6.60374 13.6892 6.4992 13.425 6.4992C13.1608 6.4992 12.9074 6.60374 12.72 6.79ZM10 0C8.02219 0 6.08879 0.586488 4.4443 1.6853C2.79981 2.78411 1.51809 4.3459 0.761215 6.17316C0.00432665 8.00042 -0.193697 10.011 0.192151 11.9508C0.577999 13.8908 1.53041 15.6725 2.92893 17.071C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98892 20.1936 11.9996 19.9956 13.8268 19.2388C15.654 18.482 17.2158 17.2002 18.3147 15.5556C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7414 7.38642 19.2388 6.17316C18.7362 4.9599 17.9997 3.85751 17.0711 2.92892C16.1424 2.00034 15.0401 1.26374 13.8268 0.761196C12.6136 0.258648 11.3132 0 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6517C4.23985 15.7727 3.21447 14.5233 2.60896 13.0614C2.00347 11.5996 1.84504 9.99112 2.15372 8.43928C2.46241 6.88742 3.22433 5.46197 4.34315 4.34314C5.46197 3.22432 6.88743 2.4624 8.43928 2.15371C9.99113 1.84504 11.5996 2.00346 13.0614 2.60896C14.5233 3.21446 15.7727 4.23984 16.6517 5.55544C17.5308 6.87103 18 8.41775 18 10C18 12.1217 17.1572 14.1566 15.6568 15.6568C14.1566 17.1572 12.1217 18 10 18Z"
				fill="currentColor"
			/>
		</Icon>
	)
}

export default CheckmarkCircle
