import { ArrivalFlight, DepartureFlight, FidsSettings } from 'types'
import { get } from './axios'

export interface FidsDataResponse {
	data: ArrivalFlight[] | DepartureFlight[]
	settings: FidsSettings
}

class FidsService {
	static loadFidsData(airportCode: string, flightRoute: string): Promise<FidsDataResponse> {
		return get(`/external/fids/${airportCode}/${flightRoute}`, {})
	}
}

export default FidsService
