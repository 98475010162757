import { Airline } from './airline'
import { Airport } from './airport'
import { Status } from './status'

export type FlightRouteType = 'arrival' | 'departure'

export enum FlightFields {
	flightNumber = 'flight_number',
	scheduledArrivalDatetime = 'scheduled_arrival_datetime',
	scheduledDepartureDatetime = 'scheduled_departure_datetime',
	originAirport = 'origin_airport',
	destinationAirport = 'destination_airport',
	airline = 'airline',
	via = 'via',
	currentEstimatedTime = 'current_estimated_time',
	currentStatus = 'current_status',
	gate = 'gate',
}

export enum ViasFields {
	airport = 'airport',
}

export interface ViasData {
	[ViasFields.airport]: Airport
}

export interface Flight {
	[FlightFields.flightNumber]: string
	[FlightFields.airline]: Airline
	[FlightFields.via]: ViasData | null
	[FlightFields.currentEstimatedTime]: string | null
	[FlightFields.currentStatus]: Status
}

export interface ArrivalFlight extends Flight {
	[FlightFields.originAirport]: Airport
	[FlightFields.scheduledArrivalDatetime]: string
}

export interface DepartureFlight extends Flight {
	[FlightFields.destinationAirport]: Airport
	[FlightFields.scheduledDepartureDatetime]: string
	[FlightFields.gate]: string
}
