import { HStack, Text, VStack } from '@chakra-ui/react'
import { isPrimaryLanguage } from 'i18n/helper'
import { useTranslation } from 'react-i18next'

import { LanguageSwitcher } from 'components'
import './styles.scss'

export default function Footer(
	props: Readonly<{
		primaryLanguageMessage: string
		secondaryLanguageMessage: string
	}>,
) {
	const { primaryLanguageMessage, secondaryLanguageMessage } = props
	const { i18n } = useTranslation()
	const textMessage = isPrimaryLanguage(i18n) ? primaryLanguageMessage : secondaryLanguageMessage

	return (
		<footer data-testid="footer" id="footer" className="footer">
			<HStack justifyContent="space-between">
				<VStack alignItems="left">{textMessage && <Text>{textMessage}</Text>}</VStack>
				<LanguageSwitcher />
			</HStack>
		</footer>
	)
}
