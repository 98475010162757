import { ChakraProvider } from '@chakra-ui/react'
import { App } from 'App'
import 'i18n'
import React from 'react'
import ReactDOM from 'react-dom/client'
import theme from 'theme'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<React.StrictMode>
		<ChakraProvider theme={theme}>
			<App />
		</ChakraProvider>
	</React.StrictMode>,
)
