import DataPair from 'components/DataPair'
import StatusTag from 'components/StatusTag'
import { parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import {
	AirlineFields,
	ArrivalFlight,
	DepartureFlight,
	FlightFields,
	FlightStatusTypeEnum,
} from 'types'
import { getTimeString } from 'utils/date'
import AirportDataPair from './AirportDataPair'
import './styles.scss'

type FidsCardProps = (
	| {
			routeType: 'arrival'
			data: ArrivalFlight
	  }
	| {
			routeType: 'departure'
			data: DepartureFlight
	  }
) & {
	showVia: boolean
	hiddenStatusCodes: FlightStatusTypeEnum[]
}

export default function FidsCard(props: FidsCardProps) {
	const { t } = useTranslation()

	const { routeType, data, showVia, hiddenStatusCodes } = props
	const {
		[FlightFields.airline]: airline,
		[FlightFields.currentEstimatedTime]: currentEstimatedTime,
		[FlightFields.currentStatus]: currentStatus,
		[FlightFields.flightNumber]: flightNumber,
		[FlightFields.via]: via,
	} = data

	const {
		[AirlineFields.icaoCode]: airlineIcao,
		[AirlineFields.largeLogoFids]: largeAirlineLogoUrl,
		[AirlineFields.smallLogoFids]: smallAirlineLogoUrl,
	} = airline

	const [airport, scheduledDatetime, gate] =
		routeType === 'arrival'
			? [
					(data as ArrivalFlight)[FlightFields.originAirport],
					(data as ArrivalFlight)[FlightFields.scheduledArrivalDatetime],
					null,
			  ]
			: [
					(data as DepartureFlight)[FlightFields.destinationAirport],
					(data as DepartureFlight)[FlightFields.scheduledDepartureDatetime],
					(data as DepartureFlight)[FlightFields.gate],
			  ]

	return (
		<div className="fids-card-container">
			<div className="top-row">
				<div className="airline-logo-slot">
					{largeAirlineLogoUrl && (
						<img
							className="large-airline-logo"
							src={largeAirlineLogoUrl}
							alt={`${airlineIcao} large logo`}
						/>
					)}
					{smallAirlineLogoUrl && (
						<img
							className="small-airline-logo"
							src={smallAirlineLogoUrl}
							alt={`${airlineIcao} small logo`}
						/>
					)}
					{!largeAirlineLogoUrl && !smallAirlineLogoUrl && airlineIcao}
				</div>
				<div>{flightNumber}</div>
			</div>
			<div className="divider" />
			<div className="bottom-row">
				<div className="airport-slot">
					<AirportDataPair routeType={routeType} airport={airport} via={via} showVia={showVia} />
				</div>
				<div className="time-slot">
					<DataPair label={t('time')} value={getTimeString(parseISO(scheduledDatetime))} />
					{routeType === 'departure' && <DataPair label={t('gate')} value={gate} />}
					<DataPair
						label={t('estimatedTime')}
						value={currentEstimatedTime && getTimeString(parseISO(currentEstimatedTime))}
					/>
				</div>
				<div className="status-slot">
					<StatusTag status={currentStatus} hiddenStatusCodes={hiddenStatusCodes} />
				</div>
			</div>
		</div>
	)
}
