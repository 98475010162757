// eslint-disable-next-line @typescript-eslint/no-explicit-any
function PointerDownIcon(props: any): JSX.Element {
	const { height, width } = props
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 33 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M16.5 6.66666V25.3333"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M25.8332 16L16.4998 25.3333L7.1665 16"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
export default PointerDownIcon
