/* eslint-disable max-len */
import { Icon, IconProps } from '@chakra-ui/react'

function ClockDefaultIcon(props: IconProps) {
	return (
		<Icon viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M8 4.00012V8.00012L10.6667 9.33346M14.6667 8.00016C14.6667 11.6821 11.6819 14.6668 8.00001 14.6668C4.31811 14.6668 1.33334 11.6821 1.33334 8.00016C1.33334 4.31826 4.31811 1.3335 8.00001 1.3335C11.6819 1.3335 14.6667 4.31826 14.6667 8.00016Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</Icon>
	)
}

export default ClockDefaultIcon
