import { format as _formatDate, parseISO } from 'date-fns'

const hourFormat = 'HH:mm'

export function parseISODateString(datestring: string): Date {
	return parseISO(datestring)
}

export function getTimeString(date: Date): string {
	return _formatDate(date, hourFormat)
}
