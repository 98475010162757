import { format } from 'date-fns'
import { isPrimaryLanguage } from 'i18n/helper'
import { PointerDownIcon } from 'icons'
import { useTranslation } from 'react-i18next'
import Strings from './constants'

import './styles.scss'

export default function DateSeparator(props: Readonly<{ date: Date }>) {
	const { date } = props

	const primaryDateString = `${(date as Date).toLocaleDateString('pt-BR', {
		month: 'long',
		day: 'numeric',
	})}`
	const secondaryDateString = format(date, 'MMMM do')

	const { i18n } = useTranslation()
	const textMessage = isPrimaryLanguage(i18n)
		? Strings.dateSeparator.portuguese(primaryDateString)
		: Strings.dateSeparator.english(secondaryDateString)

	return (
		<div className="date-separator">
			<PointerDownIcon height="50%" width="33px" />
			{textMessage}
			<PointerDownIcon height="50%" width="33px" />
		</div>
	)
}
