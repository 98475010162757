import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FlightRouteType } from 'types'

function useManagedTitle(
	airportCode: string | null | undefined,
	flightRoute: FlightRouteType | null | undefined,
): void {
	const { t } = useTranslation()
	useEffect(() => {
		const routeText = t(`title.${flightRoute}`)
		const locationText = airportCode && flightRoute ? `${airportCode} - ${routeText}` : ''
		const newTitle = [t('title.flights'), locationText, '|', t('title.CCR')].join(' ')
		document.title = newTitle
	}, [airportCode, flightRoute])
}

export default useManagedTitle
