import { Box, Icon, Text } from '@chakra-ui/react'
import { Clock } from 'components/Clock'
import { ArrivalAirplaneIcon, DepartureAirplaneIcon, LogoCCRIcon } from 'icons'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import './styles.scss'

export default function Header({ ...rest }) {
	const { t } = useTranslation()
	const { flightRoute } = useParams()

	const headerConfigs =
		flightRoute === 'arrival'
			? { title: t('header.arrivals'), icon: ArrivalAirplaneIcon }
			: { title: t('header.departures'), icon: DepartureAirplaneIcon }

	return (
		<Box
			className="header"
			justifyContent={['space-around', null, 'space-between']}
			px={['0', null, '6']}
			{...rest}
		>
			<LogoCCRIcon width={['62px', null, '62px']} height={['62px', null, '62px']} />
			<Box className="title">
				<Icon
					mr={['2', null, '4']}
					width={['20px', null, '48px']}
					height={['20px', null, '48px']}
					as={headerConfigs.icon}
				/>
				<Text fontSize={['16px', null, '36px']} casing="uppercase">
					{headerConfigs.title}
				</Text>
			</Box>
			<Clock />
		</Box>
	)
}
