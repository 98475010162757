import { ClockDefaultIcon } from 'icons'
import './styles.scss'

import { Text } from '@chakra-ui/react'
import { useUpdateCurrentTime } from 'hooks/useUpdateCurrentTime'

export interface TimeIndicatorProps {
	isUtcTime: boolean
	classname?: string | null | undefined
	time: string
	timezoneIndicator?: string | null | undefined
	[x: string]: unknown
}

export function TimeIndicator({
	time,
	timezoneIndicator,
	isUtcTime,
	...rest
}: TimeIndicatorProps): JSX.Element {
	return (
		<div className="timeZone" {...rest}>
			<ClockDefaultIcon mr="4" width={['20px', null, '28px']} height={['20px', null, '28px']} />
			<div className="timeZoneText">
				<Text fontSize={['16px', null, '32px']}>
					{!isUtcTime && timezoneIndicator ? `${timezoneIndicator}: ` : null}
					{time}
					{isUtcTime && timezoneIndicator ? ` ${timezoneIndicator}` : null}
				</Text>
			</div>
		</div>
	)
}
export interface ClockProps {
	isUtcTime?: boolean
	timezoneIndicator?: string | null | undefined
	[x: string]: unknown
}

export function Clock({ isUtcTime = false, timezoneIndicator, ...rest }: ClockProps): JSX.Element {
	const { currentTime } = useUpdateCurrentTime()

	return (
		<TimeIndicator
			time={isUtcTime ? currentTime.utcTime : currentTime.localTime}
			timezoneIndicator={timezoneIndicator}
			isUtcTime={isUtcTime}
			{...rest}
		/>
	)
}
