const brand = {
	green: {
		100: '#E5FBF6',
		500: '#00D4A1',
	},
	gray: {
		100: '#F0F0F0',
		500: '#666666',
	},
	yellow: {
		100: '#FFFAEA',
		500: '#F6C643',
	},
	red: {
		100: '#FEEBEC',
		500: '#F83446',
	},
	black: '#1D1D1D',
}

export default brand
