function accumulateDateAndFlight(acc: any[], flight: any, date: Date): any[] {
	return [
		...acc,
		{
			date,
			content_type: 'date_separator',
		},
		{ ...flight, content_type: 'flight' },
	]
}

function accumulateFlight(acc: any[], flight: any): any[] {
	return [...acc, { ...flight, content_type: 'flight' }]
}

export function insertDateSeparators(data: any[], dateFieldName: string): any[] {
	const filledData = data.reduce((acc, flight, index) => {
		const currentDate = new Date(flight[dateFieldName])
		if (index === 0) {
			const today = new Date()
			if (currentDate > today && currentDate.toDateString() !== today.toDateString())
				return accumulateDateAndFlight(acc, flight, currentDate)
			return accumulateFlight(acc, flight)
		}

		const lastItem = acc[acc.length - 1]
		const lastDate = new Date(lastItem[dateFieldName])
		if (currentDate.toDateString() !== lastDate.toDateString())
			return accumulateDateAndFlight(acc, flight, currentDate)
		return accumulateFlight(acc, flight)
	}, [])
	return filledData
}
