import { Icon, IconProps } from '@chakra-ui/react'

function ConversationIcon(props: IconProps) {
	return (
		<Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M17.0001 9H7.00011C6.73489 9 6.48054 9.10536 6.293 9.29289C6.10547 9.48043 6.00011 9.73478 6.00011 10C6.00011 10.2652 6.10547 10.5196 6.293 10.7071C6.48054 10.8946 6.73489 11 7.00011 11H17.0001C17.2653 11 17.5197 10.8946 17.7072 10.7071C17.8948 10.5196 18.0001 10.2652 18.0001 10C18.0001 9.73478 17.8948 9.48043 17.7072 9.29289C17.5197 9.10536 17.2653 9 17.0001 9ZM13.0001 13H7.00011C6.73489 13 6.48054 13.1054 6.293 13.2929C6.10547 13.4804 6.00011 13.7348 6.00011 14C6.00011 14.2652 6.10547 14.5196 6.293 14.7071C6.48054 14.8946 6.73489 15 7.00011 15H13.0001C13.2653 15 13.5197 14.8946 13.7072 14.7071C13.8948 14.5196 14.0001 14.2652 14.0001 14C14.0001 13.7348 13.8948 13.4804 13.7072 13.2929C13.5197 13.1054 13.2653 13 13.0001 13ZM12.0001 2C10.6869 2 9.38653 2.25866 8.17328 2.7612C6.96002 3.26375 5.85763 4.00035 4.92904 4.92893C3.05368 6.8043 2.00011 9.34784 2.00011 12C1.99137 14.3091 2.7909 16.5485 4.26011 18.33L2.26011 20.33C2.12135 20.4706 2.02736 20.6492 1.98998 20.8432C1.95261 21.0372 1.97353 21.2379 2.05011 21.42C2.13317 21.5999 2.26781 21.7511 2.43696 21.8544C2.6061 21.9577 2.80211 22.0083 3.00011 22H12.0001C14.6523 22 17.1958 20.9464 19.0712 19.0711C20.9465 17.1957 22.0001 14.6522 22.0001 12C22.0001 9.34784 20.9465 6.8043 19.0712 4.92893C17.1958 3.05357 14.6523 2 12.0001 2ZM12.0001 20H5.41011L6.34011 19.07C6.52636 18.8826 6.6309 18.6292 6.6309 18.365C6.6309 18.1008 6.52636 17.8474 6.34011 17.66C5.0307 16.352 4.21528 14.6305 4.0328 12.7888C3.85032 10.947 4.31205 9.09901 5.33934 7.55952C6.36662 6.02004 7.8959 4.88436 9.66663 4.34597C11.4374 3.80759 13.34 3.8998 15.0503 4.60691C16.7607 5.31402 18.173 6.59227 19.0465 8.22389C19.9201 9.85551 20.201 11.7395 19.8412 13.555C19.4815 15.3705 18.5034 17.005 17.0736 18.1802C15.6439 19.3554 13.8509 19.9985 12.0001 20Z"
				fill="currentColor"
			/>
		</Icon>
	)
}

export default ConversationIcon
