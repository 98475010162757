import './styles.scss'

export interface DataPairProps {
	label: React.ReactNode
	value: React.ReactNode
}

export default function DataPair(props: DataPairProps): JSX.Element {
	const { label, value } = props
	return (
		<div className="data-pair">
			<span className="label">{label}</span>
			<span className="value">{value ?? '--'}</span>
		</div>
	)
}
