import { Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react'
import { isPrimaryLanguage } from 'i18n/helper'
import { useTranslation } from 'react-i18next'
import { FlightStatusTypeEnum, Status, StatusFields } from 'types'
import { defaultStatusTag, StatusTagFactory } from './constants'

type StatusTagProps = Readonly<{
	status: Status
	hiddenStatusCodes: FlightStatusTypeEnum[]
}>

function getTagAssets(statusCode: FlightStatusTypeEnum, hiddenStatusCodes: FlightStatusTypeEnum[]) {
	if (!statusCode) return defaultStatusTag

	if (hiddenStatusCodes.find((hiddenCode) => hiddenCode === statusCode)) {
		return StatusTagFactory[FlightStatusTypeEnum.REPORT_TO_THE_AIRLINE]
	}

	return StatusTagFactory[statusCode]
}

export default function StatusTag(props: StatusTagProps) {
	const { status, hiddenStatusCodes } = props

	const {
		[StatusFields.code]: code,
		[StatusFields.statusEnglish]: statusEnglish,
		[StatusFields.statusTranslated]: statusTranslated,
	} = status

	const { i18n } = useTranslation()

	const { icon: Icon, color } = getTagAssets(code, hiddenStatusCodes)

	const flightStatusText = isPrimaryLanguage(i18n) ? statusTranslated : statusEnglish

	return (
		<Tag
			size="md"
			w="100%"
			sx={{
				px: '4',
				py: '2',
				bg: `brand.${color}.100`,
				color: `brand.${color}.500`,
				verticalAlign: 'middle',
			}}
			colorScheme="brand"
		>
			<TagLeftIcon
				mr="4"
				width={['16px', null, '20px']}
				height={['16px', null, '20px']}
				as={Icon}
			/>
			<TagLabel
				sx={{
					fontWeight: 'bold',
					lineHeight: '1.5',
					fontSize: 'md',
					color: 'brand.black',
				}}
			>
				{flightStatusText}
			</TagLabel>
		</Tag>
	)
}
