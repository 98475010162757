import {
	ArrowUpCircleIcon,
	CheckmarkCircle,
	ClockCircleIcon,
	ClockOnTimeIcon,
	CloseCircleIcon,
	CloudIcon,
	ConversationIcon,
	IndefiniteIcon,
	LandingIcon,
	PlaneIcon,
	SuitcaseIcon,
	TakingOffIcon,
	WorkerIcon,
} from 'icons'

import { FlightStatusTypeEnum, StatusTagFactoryType } from 'types'

export const defaultStatusTag = {
	icon: ClockOnTimeIcon,
	color: 'gray',
}

export const StatusTagFactory: StatusTagFactoryType = {
	[FlightStatusTypeEnum.CHECK_IN_OPEN]: {
		icon: SuitcaseIcon,
		color: 'green',
	},
	[FlightStatusTypeEnum.ESTIMATED]: {
		icon: ClockOnTimeIcon,
		color: 'gray',
	},
	[FlightStatusTypeEnum.CONFIRMED]: {
		icon: CheckmarkCircle,
		color: 'green',
	},
	[FlightStatusTypeEnum.PROCEED_TO_GATE]: {
		icon: ArrowUpCircleIcon,
		color: 'yellow',
	},
	[FlightStatusTypeEnum.NOW_BOARDING]: {
		icon: ArrowUpCircleIcon,
		color: 'green',
	},
	[FlightStatusTypeEnum.LAST_CALL]: {
		icon: ArrowUpCircleIcon,
		color: 'red',
	},
	[FlightStatusTypeEnum.BOARDING_CLOSED]: {
		icon: ArrowUpCircleIcon,
		color: 'gray',
	},
	[FlightStatusTypeEnum.TAKING_OFF]: {
		icon: TakingOffIcon,
		color: 'green',
	},
	[FlightStatusTypeEnum.FLIGHT_CLOSED]: {
		icon: PlaneIcon,
		color: 'gray',
	},
	[FlightStatusTypeEnum.DELAYED]: {
		icon: ClockCircleIcon,
		color: 'yellow',
	},
	[FlightStatusTypeEnum.TECHNICAL_DELAY]: {
		icon: WorkerIcon,
		color: 'yellow',
	},
	[FlightStatusTypeEnum.METEOROLOGICAL_DELAY]: {
		icon: CloudIcon,
		color: 'yellow',
	},
	[FlightStatusTypeEnum.CANCELLED]: {
		icon: CloseCircleIcon,
		color: 'red',
	},
	[FlightStatusTypeEnum.NOT_CONFIRMED]: {
		icon: IndefiniteIcon,
		color: 'red',
	},
	[FlightStatusTypeEnum.REPORT_TO_THE_AIRLINE]: {
		icon: ConversationIcon,
		color: 'yellow',
	},
	[FlightStatusTypeEnum.AIRPORT_CLOSED]: {
		icon: CloseCircleIcon,
		color: 'gray',
	},
	[FlightStatusTypeEnum.CHECK_IN_CLOSED]: {
		icon: CloseCircleIcon,
		color: 'gray',
	},
	[FlightStatusTypeEnum.DIVERTED]: {
		icon: CloseCircleIcon,
		color: 'red',
	},
	[FlightStatusTypeEnum.LANDED]: {
		icon: LandingIcon,
		color: 'green',
	},
}
