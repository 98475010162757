import { Icon } from '@chakra-ui/react'

export enum FlightStatusTypeEnum {
	CHECK_IN_OPEN = 'CO',
	ESTIMATED = 'ES',
	CONFIRMED = 'OT',
	PROCEED_TO_GATE = 'GA',
	NOW_BOARDING = 'BD',
	LAST_CALL = 'LC',
	BOARDING_CLOSED = 'BC',
	TAKING_OFF = 'AB',
	FLIGHT_CLOSED = 'FX',
	DELAYED = 'DE',
	TECHNICAL_DELAY = 'TD',
	METEOROLOGICAL_DELAY = 'DM',
	CANCELLED = 'CA',
	NOT_CONFIRMED = 'NC',
	REPORT_TO_THE_AIRLINE = 'RA',
	AIRPORT_CLOSED = 'AC',
	CHECK_IN_CLOSED = 'CC',
	DIVERTED = 'DT',
	LANDED = 'LA',
}

export enum StatusFields {
	statusTranslated = 'status_translated',
	statusEnglish = 'status_english',
	code = 'code',
}

export interface Status {
	[StatusFields.statusEnglish]: string
	[StatusFields.statusTranslated]: string
	[StatusFields.code]: FlightStatusTypeEnum
}

export type StatusTagComponentSettings = {
	icon: typeof Icon
	color: 'green' | 'gray' | 'yellow' | 'red'
}

export type StatusTagFactoryType = {
	[key in FlightStatusTypeEnum]: StatusTagComponentSettings
}
